import React from 'react';

export const LearnMore = () => {
  return (
    <>
      <div id='learn-more'>
        <h1>Learn More</h1>
      </div>
    </>
  );
};
